<template>
  <page title="我的银行卡" :show-dialog="showNew" @mask-click="showNew = false">
    <div slot="header-after" class="px-4">
      <icon color="gray-800" icon="icon-add-select" size="24" @click="newAddress"></icon>
    </div>
    <list :empty="false" empty-text="暂无银行卡" class="pt-4">
      <card class="mb-3 mx-4 p-4 rounded-lg text-white" color="purple-500" v-for="(item,index) in 3" :key="index">
        <div class="flex items-center">
          <div class="flex-1 overflow-hidden font-medium leading-none">交通银行</div>
          <div class="flex ml-3 items-center">
            <icon color="white" icon="icon-copy" class="mr-3" @click="edit(item)"></icon>
            <icon color="white" icon="icon-ashbin" @click="del(item)"></icon>
          </div>
        </div>
        <div class="py-10 px-6 text-center text-xl font-medium">3940 3304 4493 330</div>
        <div class="flex justify-between">
          <div>
            <div class="text-xs opacity-75" v-if="index == 0">默认银行卡</div>
          </div>
          <div class="text-sm">张晓明</div>
        </div>
      </card>

    </list>

    <!-- 弹出层 -->
    <div slot="dialog" >
      <div class="p-4">
        <div class="text-center text-gray-dark font-medium mb-4">{{isEdit ? '修改银行卡':'新增银行卡'}}</div>
        <cube-input v-model="value1" placeholder="银行" class="mb-3"></cube-input>
        <cube-input v-model="value2" placeholder="户名" class="mb-3"></cube-input>
        <cube-input v-model="value3" placeholder="账号" class="mb-3"></cube-input>
        <cube-input v-model="value3" placeholder="开户行" class="mb-3"></cube-input>

        <div class="flex items-center mt-4">
          <div class="flex-1 text-sm">是否默认</div>
          <cube-switch v-model="isDefault" />
        </div>
      </div>
      <btn radius="none" text-size="base" block @click="submit">保存</btn>
    </div>
  </page>
</template>

<script>
export default {
  data(){
    return{
      showNew: false, 
      value1:'',
      value2:'',
      value3:'',
      isDefault: false,
      isEdit: false
    }
  },
  methods:{
    del(e) {
      console.log('删除',e);
      this.showAlert(e)
    },
    showAlert(e) {
      this.dialog = this.$createDialog({
        type: 'confirm',
        title: '警告',
        content: `确定要删除${e}？`,
        onConfirm: (e, promptValue) => {
          this.$createToast({
            type: 'warn',
            time: 1000,
            txt: `已删除`
          }).show()
        },
      }).show()
    },
    newAddress() {
      console.log('添加');
      this.showNew = true;
      this.isEdit = false
    },
    submit() {
      console.log('提交');
      this.showNew = false;
    },
    edit(item) {
      this.showNew = true;
      this.isEdit = true
    }
  },
  created(){}
}
</script>

<style lang='stylus'>

</style>